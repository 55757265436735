/*------------------------------------------------*\
  Mixins
\*------------------------------------------------*/

@mixin square($size: 20px) {
	width: $size;
	height: $size;
}

@mixin round($size: 20px) {
	@include square($size);
	border-radius: 50%;
}

@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/*------------------------------------------------*\
  Target
\*------------------------------------------------*/

$target-round-size: 18px;
$target-round-border-color: lighten(#000, 70%);
$target-triangle-size: 10px;
$target-triangle-color: lighten(#000, 70%);

@keyframes target {
	0% {
		transform: rotateZ(0) scale(0.6);
	}
	50% {
		transform: rotateZ(180deg) scale(1);
	}
	100% {
		transform: rotateZ(360deg) scale(0.6);
	}
}

.target {
	position: relative;
	background-color: #fff;
	border: 2px solid $target-round-border-color;
	box-shadow: 0 0 4px 2px rgba(#000, 0.2);
	animation: target 2s linear infinite;

	@include round($target-round-size);

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
	}

	&:before {
		top: 16px;
		left: 16px;

		width: 0;
		height: 0;
		border-style: solid;
		border-width: $target-triangle-size $target-triangle-size 0 0;
		border-color: $target-triangle-color transparent transparent transparent;
	}

	&:after {
		bottom: 16px;
		right: 16px;

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 $target-triangle-size $target-triangle-size;
		border-color: transparent transparent $target-triangle-color transparent;
	}
}

/*------------------------------------------------*\
  sq
\*------------------------------------------------*/

$sq-color: #46d9c0;
$sq-color-dark: darken($sq-color, 20%);

@keyframes sq {
	from {
		box-shadow: 0 0 0 0 rgba(#fff, 1), 0 0 0 0 rgba(#fff, 1);
	}
	to {
		box-shadow: 0 0 0 8px rgba(#fff, 0), 0 0 0 12px rgba(#fff, 0);
	}
}

.sq {
	@include square(24px);

	position: relative;
	border-radius: 20%;
	background-color: rgba($sq-color, 0.6);
	box-shadow: inset 0 0 8px 6px rgba($sq-color-dark, 0.4),
		0 0 4px 2px rgba(#000, 0.2);
	transform: rotateZ(45deg);

	&:before {
		@include round(6px);
		@include center;

		content: "";
		display: block;
		background-color: rgba(#fff, 0.4);
		animation: sq 800ms infinite;
	}
}

/*------------------------------------------------*\
  Morph
\*------------------------------------------------*/

$morph-color: #f72d41;
$morph-size: 25px;
$morph-inner-size: $morph-size / 2;

@keyframes morph {
	0% {
		transform: rotateZ(0);
		border-radius: 20%;
	}
	50% {
		transform: rotateZ(45deg);
		border-radius: 50%;
	}
	100% {
		transform: rotateZ(90deg);
		border-radius: 20%;
	}
}

.morph {
	@include square($morph-size);

	position: relative;
	box-shadow: 0 0 6px 4px rgba($morph-color, 0.4);
	animation: morph 1s linear infinite;

	&,
	&:before {
		background-color: rgba($morph-color, 0.6);
	}

	&:before {
		@include center;
		@include round($morph-inner-size);

		content: "";
		display: block;
	}
}

/*------------------------------------------------*\
  Egg
\*------------------------------------------------*/

$egg-color: #f77e2d;
$egg-size: 25px;
$egg-center-size: $egg-size / 2;

@keyframes egg {
	from {
		box-shadow: inset 0 0 12px 6px rgba($egg-color, 0.6);
	}
	to {
		box-shadow: inset 0 0 4px 2px rgba($egg-color, 0.4);
	}
}

.egg {
	@include round($egg-size);

	position: relative;
	box-shadow: inset 0 0 6px 4px rgba($egg-color, 0.6);
	animation: egg 600ms linear infinite;
	animation-direction: alternate;

	&:before {
		@include round($egg-center-size);
		@include center;

		content: "";
		display: block;
		background-color: rgba($egg-color, 0.8);
		border: 2px solid $egg-color;
	}
}

/*------------------------------------------------*\
  Wheel
\*------------------------------------------------*/

$green: #37b475;
$wheel-size: 40px;
$wheel-decrement: 20px;
$wheel-border-width: 4px;

@keyframes wheel {
	from {
		transform: rotateZ(0);
	}
	to {
		transform: rotateZ(-360deg);
	}
}

.wheel {
	@include round($wheel-size);

	position: relative;
	border: $wheel-border-width dashed $green;
	animation: wheel 6s linear infinite;
	box-shadow: inset 0 0 4px 2px rgba($green, 0.6);

	&:before {
		@include round($wheel-size - $wheel-decrement);
		@include center;

		content: "";
		display: block;
		background-color: $green;
	}
}

/*------------------------------------------------*\
  Wheel Alt
\*------------------------------------------------*/

.wheel-alt {
	box-shadow: inset 0 0 0 2px lighten($green, 10%);
	border-color: lighten($green, 10%);

	&:before {
		border: 2px solid darken($green, 5%);
		box-shadow: 0 0 2px 2px rgba(#000, 0.3);
	}
}

/*------------------------------------------------*\
  Wheel Alt 2
\*------------------------------------------------*/

.wheel-alt2 {
	box-shadow: none;
	border-color: darken($green, 5%);

	&:before {
		border: 2px solid darken($green, 5%);
	}
}

/*------------------------------------------------*\
  Light
\*------------------------------------------------*/

$blue: #67c1ea;
$light-bg: white;

@keyframes light {
	0% {
		box-shadow: inset 0 0 0 0 $blue, inset 0 0 0 0 $light-bg;
	}
	50% {
		box-shadow: inset 0 0 0 0 $blue, inset 0 0 0 15px $light-bg;
	}
	100% {
		box-shadow: inset 0 0 0 15px $blue, inset 0 0 0 15px $light-bg;
	}
}

.light {
	@include round(30px);

	animation: light 1.2s infinite;
	animation-direction: reverse;
	animation-timing-function: cubic-bezier(0.71, 0.13, 1, 0.71);
	background-color: $blue;
}

/*------------------------------------------------*\
  Slack
\*------------------------------------------------*/

$white: #fff;
$black: #000;
$slack-size: 25px;
$slack-ring-size: $slack-size * 1.1;
$slack-ring-border: 4px;
$slack-increment: 10px;

@keyframes slack {
	from {
		transform: translate(-50%, -50%) scale(1);
	}

	to {
		transform: translate(-50%, -50%) scale(1.5);
	}
}

.slack {
	@include round($slack-size);

	position: relative;
	background-color: $white;
	box-shadow: 0 0 4px 2px rgba($black, 0.2);

	&:before {
		@include center;
		@include round($slack-ring-size);

		content: "";
		display: block;
		border: $slack-ring-border solid $white;
		box-shadow: inset 0 0 4px 2px rgba($black, 0.2),
			0 0 4px 2px rgba($black, 0.2);
		animation: slack 600ms linear infinite;
		animation-direction: alternate;
	}
}

/*------------------------------------------------*\
  Sonar
\*------------------------------------------------*/

$pink: #ff93df;
$sonar-size: 25px;

@keyframes sonar {
	from {
		box-shadow: 0 0 0 0 rgba($pink, 1), 0 0 4px 2px rgba(#000, 0.4);
	}
	to {
		box-shadow: 0 0 0 10px rgba($pink, 0), 0 0 4px 2px rgba(#000, 0.4);
	}
}

.sonar {
	@include round($sonar-size);

	background-color: $pink;
	border: 2px solid darken($pink, 10%);
	animation: sonar 800ms ease-out infinite;
}

/*------------------------------------------------*\
  Uninteresting stuff
\*------------------------------------------------*/

$item-size: 150px;
$item-margin: 10px;

*,
*:before,
*:after {
	box-sizing: border-box;
}

.wrapper {
	width: 5 * ($item-size + $item-margin * 2);
	margin: 20px auto;
}

.item {
	display: table;
	float: left;
	width: $item-size;
	height: $item-size;
	border: 2px solid #dadada;
	margin: $item-margin;
	&:nth-of-type(even) {
		background-color: #eee;
	}
}

.item-inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;

	> p {
		text-decoration: none;
		display: inline-block;
	}
}
