.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 560px;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;

}

.tags-input > input {
    flex: 1;
    border: none;
    outline: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
}

.tags-input > input:focus {
    box-shadow: none;
}

#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #0052cc;
}

.tag .tag-title {
    margin-bottom: 2px;
}

.tag .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.tags-input:focus-within {
    border: 1px solid #0052cc;
}


@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}