@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:wght@300&family=Readex+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100;200;300;400;500;600;700;900&family=Lato:wght@300&family=Readex+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900;1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: "Proxima Nova", system-ui, sans-serif;
	}
}

.react-confirm-alert-button-group > button {
	background-color: rgb(7, 42, 136) !important;
}

.react-confirm-alert-body > h1 {
	font-size: xx-large !important;
	margin-bottom: 1rem !important;
}

.react-confirm-alert-overlay {
	background: rgba(0, 0, 0, 0.32) !important;
	backdrop-filter: blur(5px) !important;
}

.__react_component_tooltip {
	padding: 5px 10px !important;
	border-left: 0 solid #222222 !important;
	border-radius: 6px !important;
	color: #fbfbfb !important;
	background-color: #222222 !important;
	font-family: "Proxima Nova", system-ui, sans-serif !important;
}
.__react_component_tooltip.place-left::after {
	border-left: 0 solid #222222 !important;
}

.__react_component_tooltip.place-right::after {
	border-right: 0 solid #222222 !important;
}

.__react_component_tooltip.place-top::after {
	border-top: 0 solid #222222 !important;
}

.__react_component_tooltip.place-bottom::after {
	border-bottom: 0 solid #222222 !important;
}

.Toastify__toast--error {
	border: 2px solid #be8181;
	background: #edc8c5 !important;
	color: #b4302b !important;
}

.Toastify__toast--success {
	border: 2px solid #afcda4;
	background: #ddf3d5 !important;
	color: #597151 !important;
}

.Toastify__toast--default {
	border: 2px solid #7dbce0;
	background: #cde8f6 !important;
	color: #3479b0 !important;
}

.svg-color-7D7D7D {
	filter: brightness(0) saturate(100%) invert(57%) sepia(0%) saturate(11%) hue-rotate(144deg) brightness(86%) contrast(84%);
}
